// import image from './megaMenuImages/columnorblind_test.jpg';
import image2 from './megaMenuImages/images.jpeg';
import image3 from './megaMenuImages/test.jpg';
import gucci from './megaMenuImages/gucci.jpg';
import prada from './megaMenuImages/prada.jpg';
import gucci_portrait from './megaMenuImages/gucci_portrait.png';
const traverse = require('traverse');
import { languageType } from './util/languageType';
import { stubFalse } from 'lodash';

// {
//   main: {
//     label: 'New In',
//     url: '',
//   },
//   column: [
//     {
//       title: '',
//       sub: [],
//     },
//   ],
//   clickable: false,
//   hoverable: false,
// },
const megaMenu = [
  {
    main: {
      label: 'New In|Νέες Αφίξεις',
      url: '/s?pub_department=women',
    },
    column: [],
    clickable: true,
    hoverable: false,
  },
  {
    main: {
      label: 'Top Brands|Top Brands',
      url: '/s?pub_category=bags',
    },
    column: [
      {
        title: '',
        sub: [
          {
            label: 'Louis Vuitton|Louis Vuitton',
            url: 's?pub_department=women&pub_designer=louisVuitton',
          },
          {
            label: 'Gucci|Gucci',
            url: 's?pub_designer=gucci',
          },
          {
            label: 'Chanel|Chanel',
            url: '/s?pub_designer=chanel',
          },
          {
            label: 'Prada|Prada',
            url: '/s?pub_department=women&pub_designer=prada',
          },
          {
            label: 'Hermes|Hermes',
            url: '/s?pub_department=women&pub_designer=hermes',
          },
        ],
      },
    ],

    clickable: false,
    hoverable: true,
    image: {
      height: '300px',
      width: '300px',
      imageUrl: image2,
    },
  },
  
  {
    main: {
      label: 'Bags|Τσάντες',
      url: '/s?pub_category=bags',
    },
    column: [
      {
        title: '',
        sub: [
          {
            label: 'All bags|Όλες οι τσάντες',
            url: '/s?pub_category=bags',
          },
          {
            label: 'Handbags|Τσάντες χειρός',
            url: '/s?pub_category=bags&pub_subCategory=handbags',
          },
          {
            label: 'Clutch bags|Τσάντες clutch',
            url: '/s?pub_category=bags&pub_subCategory=clutchbags',
          },
          {
            label: 'Backpacks|Σακίδια',
            url: '/s?pub_category=bags&pub_subCategory=backpacks',
          },
          {
            label: 'Travelbags|Τσάντες Ταξιδιού',
            url: '/s?pub_category=bags&pub_subCategory=travelbags',
          },
        ],
      },
    ],

    clickable: true,
    hoverable: true,
    image: {
      height: '300px',
      width: '300px',
      imageUrl: image2,
    },
  },
  
  {
    main: {
      label: 'Accessories|Αξεσουάρ',
      url: '/s?pub_category=accessories',
    },
    column: [
      {
        title: '',
        sub: [
          {
            label: 'All accessories|Όλα τα αξεσουάρ',
            url: '/s?pub_category=accessories',
          },
          {
            label: 'Wallets|Πορτοφόλια',
            url: '/s?pub_category=accessories&pub_subCategory=wallets',
          },
          {
            label: 'Belts|Ζώνες',
            url: '/s?pub_category=accessories&pub_subCategory=belts',
          },
          {
            label: 'Scarves|Μαντήλια',
            url: '/s?pub_category=accessories&pub_subCategory=scarf',
          },
          {
            label: 'Sunglasses|Γυαλιά ηλίου',
            url: '/s?pub_category=accessories&pub_subCategory=sunglasses',
          },
          {
            label: 'Hats|Καπέλα',
            url: '/s?pub_category=accessories&pub_subCategory=hats',
          },
        ],
      },
    ],

    clickable: true,
    hoverable: true,
    image: {
      height: '300px',
      width: '',
      imageUrl: image3,
    },
  },
  {
    main: {
      label: 'Jewelry|Κοσμήματα',
      url: '/s?pub_category=jewelry',
    },
    column: [
      {
        title: '',
        sub: [
          {
            label: 'All jewelry|Όλα τα κοσμήματα',
            url: '/s?pub_category=jewelry',
          },
          {
            label: 'Bracelets|Βραχιόλια',
            url: '/s?pub_category=jewelry&pub_subCategory=bracelet',
          },
          {
            label: 'Earrings|Σκουλαρίκια',
            url: '/s?pub_category=jewelry&pub_subCategory=earring',
          },
          {
            label: 'Necklaces|Κολιέ',
            url: '/s?pub_category=jewelry&pub_subCategory=necklace',
          },
          {
            label: 'Rings|Δαχτυλίδια',
            url: '/s?pub_category=jewelry&pub_subCategory=ring',
          },
          {
            label: 'Watches|Ρολόγια',
            url: '/s?pub_category=jewelry&pub_subCategory=watch',
          },
        ],
      },
    ],
    clickable: true,
    hoverable: true,
    image: {
      height: '300px',
      width: '',
      imageUrl: gucci_portrait,
    },
  },
  {
    main: {
      label: 'Clothing|Ρούχα',
      url: '/s?pub_category=clothing',
    },
    column: [
      {
        title: '',
        sub: [
          {
            label: 'Coats & Jackets|Παλτό & Μπουφάν',
            url: '/s?pub_category=clothing&pub_subCategory=coats',
          },
          {
            label: 'Dresses|Φορέματα',
            url: '/s?pub_category=clothing&pub_subCategory=dresses',
          },
          {
            label: 'Knitwear|Πλεκτά',
            url: '/s?pub_category=clothing&pub_subCategory=knitwear',
          },
          {
            label: 'Tops|Μπλούζες',
            url: '/s?pub_category=clothing&pub_subCategory=tops',
          },
          {
            label: 'Skirts|Φούστες',
            url: '/s?pub_category=clothing&pub_subCategory=skirts',
          },
          {
            label: 'Shorts|Σορτς',
            url: '/s?pub_category=clothing&pub_subCategory=shorts',
          },
          {
            label: 'Trousers & Leggings|Παντελόνια & Κολάν',
            url: '/s?pub_category=clothing&pub_subCategory=trousers',
          },
          {
            label: 'Jumpsuits|Ολόσωμες Φόρμες',
            url: '/s?pub_category=clothing&pub_subCategory=jumpsuits',
          },
          {
            label: 'Sports Bras|Αθλητικά Σουτιέν',
            url: '/s?pub_category=clothing&pub_subCategory=sportsBras',
          },
          {
            label: 'Shop All Clothing >|Όλα τα Ρούχα >',
            url: '/s?pub_category=clothing',
          },
        ],
      },
    ],

    clickable: true,
    hoverable: false,
    image: {
      height: '300px',
      width: '',
      imageUrl: gucci_portrait,
    },
  },
  {
    main: {
      label: 'Shoes|Παπούτσια',
      url: '/s?pub_category=shoes',
    },
    column: [],

    clickable: true,
    hoverable: false,
    image: {
      height: '300px',
      width: '',
      imageUrl: image3,
    },
  },
  {
    main: {
      label: 'About Pop Nouvelle|Σχετικά με το Pop Nouvelle',
      url: '/p/about',
    },

    clickable: true,
    hoverable: false,
  },
];

const getMegaMenuData = lang => {
  const megaMenuData = JSON.parse(JSON.stringify(megaMenu));

  traverse(megaMenuData).forEach(function(_) {
    if (_['label']) {
      var label = _['label'].split('|');
      if (label.length > 1 && lang == 'el') {
        _['label'] = label[1];
      } else {
        _['label'] = label[0];
      }
    }

    if (_['title']) {
      var title = _['title'].split('|');
      if (title.length > 1 && lang == 'el') {
        _['title'] = title[1];
      } else {
        _['title'] = title[0];
      }
    }
  });

  return megaMenuData;
};

export { getMegaMenuData };
