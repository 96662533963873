import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
export const ASSET_NAME = 'terms-of-service';
export const GREEK_ASSET_NAME = 'terms-of-service-greek';
import { languageType } from '../../util/languageType';

export const loadData = (params, search) => dispatch => {
  const pageAsset = {
    termsOfService: `content/pages/${
      languageType() == 'en-US' ? ASSET_NAME : GREEK_ASSET_NAME
    }.json`,
  };
  return dispatch(fetchPageAssets(pageAsset, true));
};
