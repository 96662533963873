import { languageType } from '../util/languageType';
import en from '../translations/en.json';
import gr from '../translations/gr.json';
import cookie from '../assets/cookie.svg';

const imgTag = `<img src="${cookie}" alt="cookie" style="width: 20px; height: 20px;"/>`;

console.log('languageType', languageType());

export const cookieConfig = {
  onChange: () => {
    window.location.reload();
  },
  guiOptions: {
    consentModal: {
      layout: 'box',
      position: 'bottom',
      equalWeightButtons: true,
      flipButtons: true,
      closeIconLabel: en['CookieConfig.ConsentModal.CloseIconLabel'],
    },
    preferencesModal: {
      layout: 'box',
      position: 'right',
      equalWeightButtons: true,
      flipButtons: false,
      closeIconLabel: en['CookieConfig.PreferencesModal.CloseIconLabel'],
    },
  },
  mode: 'opt-in',
  categories: {
    necessary: {
      enabled: true, // this category is enabled by default
      readOnly: true, // this category cannot be disabled
    },
    analytics: {
      enabled: false,
      services: {
        GoogleAnalytics: {
          label: 'Google Analytics',
          onAccept: () => {
            // window.location.reload()
          },
          onReject: () => {
            window[`ga-disable-${googleAnalyticsId}`] = true;
          },
          cookies: [
            {
              name: /^(_ga)/,
            },
          ],
        },
      },
    },
    advertising: {
      enabled: false,
      services: {
        GoogleAds: {
          label: 'Google Ads',
          onAccept: () => {},
          onReject: () => {},
          cookies: [
            {
              name: /_gcl_au/,
            },
          ],
        },
        FacebookPixel: {
          label: 'Meta Pixel',
          onAccept: () => {},
          onReject: () => {},
          cookies: [
            {
              name: /_fbp/,
            },
          ],
        },
      },
    },
  },
  language: {
    default: `${languageType() === 'el' ? 'el' : 'en'}`,
    translations: {
      en: {
        consentModal: {
          title: en['CookieConfig.ConsentModal.Title'] + imgTag,
          description: en['CookieConfig.ConsentModal.Description'],
          acceptAllBtn: en['CookieConfig.ConsentModal.AcceptAllBtn'],
          showPreferencesBtn: en['CookieConfig.ConsentModal.ShowPreferencesBtn'],
          closeIconLabel: en['CookieConfig.ConsentModal.CloseIconLabel'],
          acceptNecessaryBtn: en['CookieConfig.ConsentModal.AcceptNecessaryBtn'],
        },
        preferencesModal: {
          title: en['CookieConfig.PreferencesModal.Title'],
          acceptAllBtn: en['CookieConfig.PreferencesModal.AcceptAllBtn'],
          savePreferencesBtn: en['CookieConfig.PreferencesModal.SavePreferencesBtn'],
          closeIconLabel: en['CookieConfig.PreferencesModal.CloseIconLabel'],
          sections: [
            {
              title: en['CookieConfig.PreferencesModal.Sections.CookieUsage.Title'],
              description: en['CookieConfig.PreferencesModal.Sections.CookieUsage.Description'],
            },
            {
              title: en['CookieConfig.PreferencesModal.Sections.StrictlyNecessary.Title'],
              description:
                en['CookieConfig.PreferencesModal.Sections.StrictlyNecessary.Description'],
              linkedCategory: 'necessary',
              cookieTable: {
                headers: {
                  name:
                    en[
                      'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Headers.Name'
                    ],
                  domain:
                    en[
                      'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Headers.Domain'
                    ],
                  description:
                    en[
                      'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Headers.Description'
                    ],
                  expiration:
                    en[
                      'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Headers.Expiration'
                    ],
                },
                body: [
                  {
                    name:
                      en[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.stHostedToken.Name'
                      ],
                    domain:
                      en[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.stHostedToken.Domain'
                      ],
                    description:
                      en[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.stHostedToken.Description'
                      ],
                    expiration:
                      en[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.stHostedToken.Expiration'
                      ],
                  },
                  {
                    name:
                      en[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.Language.Name'
                      ],
                    domain:
                      en[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.Language.Domain'
                      ],
                    description:
                      en[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.Language.Description'
                      ],
                    expiration:
                      en[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.Language.Expiration'
                      ],
                  },
                  {
                    name:
                      en[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.StripeMid.Name'
                      ],
                    domain:
                      en[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.StripeMid.Domain'
                      ],
                    description:
                      en[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.StripeMid.Description'
                      ],
                    expiration:
                      en[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.StripeMid.Expiration'
                      ],
                  },
                  {
                    name:
                      en[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.StripeSid.Name'
                      ],
                    domain:
                      en[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.StripeSid.Domain'
                      ],
                    description:
                      en[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.StripeSid.Description'
                      ],
                    expiration:
                      en[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.StripeSid.Expiration'
                      ],
                  },
                ],
              },
            },
            {
              title: en['CookieConfig.PreferencesModal.Sections.Advertising.Title'],
              description: en['CookieConfig.PreferencesModal.Sections.Advertising.Description'],
              linkedCategory: 'advertising',
              cookieTable: {
                headers: {
                  name:
                    en[
                      'CookieConfig.PreferencesModal.Sections.Advertising.CookieTable.Headers.Name'
                    ],
                  domain:
                    en[
                      'CookieConfig.PreferencesModal.Sections.Advertising.CookieTable.Headers.Domain'
                    ],
                  description:
                    en[
                      'CookieConfig.PreferencesModal.Sections.Advertising.CookieTable.Headers.Description'
                    ],
                  expiration:
                    en[
                      'CookieConfig.PreferencesModal.Sections.Advertising.CookieTable.Headers.Expiration'
                    ],
                },
                body: [
                  {
                    name:
                      en[
                        'CookieConfig.PreferencesModal.Sections.Advertising.CookieTable.Body.Fbp.Name'
                      ],
                    domain:
                      en[
                        'CookieConfig.PreferencesModal.Sections.Advertising.CookieTable.Body.Fbp.Domain'
                      ],
                    description:
                      en[
                        'CookieConfig.PreferencesModal.Sections.Advertising.CookieTable.Body.Fbp.Description'
                      ],
                    expiration:
                      en[
                        'CookieConfig.PreferencesModal.Sections.Advertising.CookieTable.Body.Fbp.Expiration'
                      ],
                  },
                  {
                    name:
                      en[
                        'CookieConfig.PreferencesModal.Sections.Advertising.CookieTable.Body.GclAu.Name'
                      ],
                    domain:
                      en[
                        'CookieConfig.PreferencesModal.Sections.Advertising.CookieTable.Body.GclAu.Domain'
                      ],
                    description:
                      en[
                        'CookieConfig.PreferencesModal.Sections.Advertising.CookieTable.Body.GclAu.Description'
                      ],
                    expiration:
                      en[
                        'CookieConfig.PreferencesModal.Sections.Advertising.CookieTable.Body.GclAu.Expiration'
                      ],
                  },
                ],
              },
            },
            {
              title: en['CookieConfig.PreferencesModal.Sections.PerformanceAnalytics.Title'],
              description:
                en['CookieConfig.PreferencesModal.Sections.PerformanceAnalytics.Description'],
              linkedCategory: 'analytics',
              cookieTable: {
                headers: {
                  name:
                    en[
                      'CookieConfig.PreferencesModal.Sections.PerformanceAnalytics.CookieTable.Headers.Name'
                    ],
                  domain:
                    en[
                      'CookieConfig.PreferencesModal.Sections.PerformanceAnalytics.CookieTable.Headers.Domain'
                    ],
                  description:
                    en[
                      'CookieConfig.PreferencesModal.Sections.PerformanceAnalytics.CookieTable.Headers.Description'
                    ],
                  expiration:
                    en[
                      'CookieConfig.PreferencesModal.Sections.PerformanceAnalytics.CookieTable.Headers.Expiration'
                    ],
                },
                body: [
                  {
                    name:
                      en[
                        'CookieConfig.PreferencesModal.Sections.PerformanceAnalytics.CookieTable.Body.Ga.Name'
                      ],
                    domain:
                      en[
                        'CookieConfig.PreferencesModal.Sections.PerformanceAnalytics.CookieTable.Body.Ga.Domain'
                      ],
                    description:
                      en[
                        'CookieConfig.PreferencesModal.Sections.PerformanceAnalytics.CookieTable.Body.Ga.Description'
                      ],
                    expiration:
                      en[
                        'CookieConfig.PreferencesModal.Sections.PerformanceAnalytics.CookieTable.Body.Ga.Expiration'
                      ],
                  },
                ],
              },
            },
            {
              title: en['CookieConfig.PreferencesModal.Sections.MoreInformation.Title'],
              description: en['CookieConfig.PreferencesModal.Sections.MoreInformation.Description'],
            },
          ],
        },
      },
      el: {
        consentModal: {
          title: gr['CookieConfig.ConsentModal.Title'] + imgTag,
          description: gr['CookieConfig.ConsentModal.Description'],
          acceptAllBtn: gr['CookieConfig.ConsentModal.AcceptAllBtn'],
          showPreferencesBtn: gr['CookieConfig.ConsentModal.ShowPreferencesBtn'],
          closeIconLabel: gr['CookieConfig.ConsentModal.CloseIconLabel'],
          acceptNecessaryBtn: gr['CookieConfig.ConsentModal.AcceptNecessaryBtn'],
        },
        preferencesModal: {
          title: gr['CookieConfig.PreferencesModal.Title'],
          acceptAllBtn: gr['CookieConfig.PreferencesModal.AcceptAllBtn'],
          savePreferencesBtn: gr['CookieConfig.PreferencesModal.SavePreferencesBtn'],
          closeIconLabel: gr['CookieConfig.PreferencesModal.CloseIconLabel'],
          sections: [
            {
              title: gr['CookieConfig.PreferencesModal.Sections.CookieUsage.Title'],
              description: gr['CookieConfig.PreferencesModal.Sections.CookieUsage.Description'],
            },
            {
              title: gr['CookieConfig.PreferencesModal.Sections.StrictlyNecessary.Title'],
              description:
                gr['CookieConfig.PreferencesModal.Sections.StrictlyNecessary.Description'],
              linkedCategory: 'necessary',
              cookieTable: {
                headers: {
                  name:
                    gr[
                      'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Headers.Name'
                    ],
                  domain:
                    gr[
                      'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Headers.Domain'
                    ],
                  description:
                    gr[
                      'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Headers.Description'
                    ],
                  expiration:
                    gr[
                      'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Headers.Expiration'
                    ],
                },
                body: [
                  {
                    name:
                      gr[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.stHostedToken.Name'
                      ],
                    domain:
                      gr[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.stHostedToken.Domain'
                      ],
                    description:
                      gr[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.stHostedToken.Description'
                      ],
                    expiration:
                      gr[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.stHostedToken.Expiration'
                      ],
                  },
                  {
                    name:
                      gr[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.Language.Name'
                      ],
                    domain:
                      gr[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.Language.Domain'
                      ],
                    description:
                      gr[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.Language.Description'
                      ],
                    expiration:
                      gr[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.Language.Expiration'
                      ],
                  },
                  {
                    name:
                      gr[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.StripeMid.Name'
                      ],
                    domain:
                      gr[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.StripeMid.Domain'
                      ],
                    description:
                      gr[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.StripeMid.Description'
                      ],
                    expiration:
                      gr[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.StripeMid.Expiration'
                      ],
                  },
                  {
                    name:
                      gr[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.StripeSid.Name'
                      ],
                    domain:
                      gr[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.StripeSid.Domain'
                      ],
                    description:
                      gr[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.StripeSid.Description'
                      ],
                    expiration:
                      gr[
                        'CookieConfig.PreferencesModal.Sections.StrictlyNecessary.CookieTable.Body.StripeSid.Expiration'
                      ],
                  },
                ],
              },
            },

            {
              title: gr['CookieConfig.PreferencesModal.Sections.Advertising.Title'],
              description: gr['CookieConfig.PreferencesModal.Sections.Advertising.Description'],
              linkedCategory: 'advertising',
              cookieTable: {
                headers: {
                  name:
                    gr[
                      'CookieConfig.PreferencesModal.Sections.Advertising.CookieTable.Headers.Name'
                    ],
                  domain:
                    gr[
                      'CookieConfig.PreferencesModal.Sections.Advertising.CookieTable.Headers.Domain'
                    ],
                  description:
                    gr[
                      'CookieConfig.PreferencesModal.Sections.Advertising.CookieTable.Headers.Description'
                    ],
                  expiration:
                    gr[
                      'CookieConfig.PreferencesModal.Sections.Advertising.CookieTable.Headers.Expiration'
                    ],
                },
                body: [
                  {
                    name:
                      gr[
                        'CookieConfig.PreferencesModal.Sections.Advertising.CookieTable.Body.Fbp.Name'
                      ],
                    domain:
                      gr[
                        'CookieConfig.PreferencesModal.Sections.Advertising.CookieTable.Body.Fbp.Domain'
                      ],
                    description:
                      gr[
                        'CookieConfig.PreferencesModal.Sections.Advertising.CookieTable.Body.Fbp.Description'
                      ],
                    expiration:
                      gr[
                        'CookieConfig.PreferencesModal.Sections.Advertising.CookieTable.Body.Fbp.Expiration'
                      ],
                  },
                  {
                    name:
                      gr[
                        'CookieConfig.PreferencesModal.Sections.Advertising.CookieTable.Body.GclAu.Name'
                      ],
                    domain:
                      gr[
                        'CookieConfig.PreferencesModal.Sections.Advertising.CookieTable.Body.GclAu.Domain'
                      ],
                    description:
                      gr[
                        'CookieConfig.PreferencesModal.Sections.Advertising.CookieTable.Body.GclAu.Description'
                      ],
                    expiration:
                      gr[
                        'CookieConfig.PreferencesModal.Sections.Advertising.CookieTable.Body.GclAu.Expiration'
                      ],
                  },
                ],
              },
            },
            {
              title: gr['CookieConfig.PreferencesModal.Sections.PerformanceAnalytics.Title'],
              description:
                gr['CookieConfig.PreferencesModal.Sections.PerformanceAnalytics.Description'],
              linkedCategory: 'analytics',
              cookieTable: {
                headers: {
                  name:
                    gr[
                      'CookieConfig.PreferencesModal.Sections.PerformanceAnalytics.CookieTable.Headers.Name'
                    ],
                  domain:
                    gr[
                      'CookieConfig.PreferencesModal.Sections.PerformanceAnalytics.CookieTable.Headers.Domain'
                    ],
                  description:
                    gr[
                      'CookieConfig.PreferencesModal.Sections.PerformanceAnalytics.CookieTable.Headers.Description'
                    ],
                  expiration:
                    gr[
                      'CookieConfig.PreferencesModal.Sections.PerformanceAnalytics.CookieTable.Headers.Expiration'
                    ],
                },
                body: [
                  {
                    name:
                      gr[
                        'CookieConfig.PreferencesModal.Sections.PerformanceAnalytics.CookieTable.Body.Ga.Name'
                      ],
                    domain:
                      gr[
                        'CookieConfig.PreferencesModal.Sections.PerformanceAnalytics.CookieTable.Body.Ga.Domain'
                      ],
                    description:
                      gr[
                        'CookieConfig.PreferencesModal.Sections.PerformanceAnalytics.CookieTable.Body.Ga.Description'
                      ],
                    expiration:
                      gr[
                        'CookieConfig.PreferencesModal.Sections.PerformanceAnalytics.CookieTable.Body.Ga.Expiration'
                      ],
                  },
                ],
              },
            },
            {
              title: gr['CookieConfig.PreferencesModal.Sections.MoreInformation.Title'],
              description: gr['CookieConfig.PreferencesModal.Sections.MoreInformation.Description'],
            },
          ],
        },
      },
    },
  },
};
