import { updatedEntities, denormalisedEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { languageType } from '../../util/languageType';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 42;

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/ManageListingsPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ManageListingsPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ManageListingsPage/FETCH_LISTINGS_ERROR';

export const OPEN_LISTING_REQUEST = 'app/ManageListingsPage/OPEN_LISTING_REQUEST';
export const OPEN_LISTING_SUCCESS = 'app/ManageListingsPage/OPEN_LISTING_SUCCESS';
export const OPEN_LISTING_ERROR = 'app/ManageListingsPage/OPEN_LISTING_ERROR';

export const CLOSE_LISTING_REQUEST = 'app/ManageListingsPage/CLOSE_LISTING_REQUEST';
export const CLOSE_LISTING_SUCCESS = 'app/ManageListingsPage/CLOSE_LISTING_SUCCESS';
export const CLOSE_LISTING_ERROR = 'app/ManageListingsPage/CLOSE_LISTING_ERROR';

export const ADD_OWN_ENTITIES = 'app/ManageListingsPage/ADD_OWN_ENTITIES';
export const DISCARD_LISTING_REQUEST = 'app/ManageListingsPage/DISCARD_LISTING_REQUEST';
export const DISCARD_LISTING_SUCCESS = 'app/ManageListingsPage/DISCARD_LISTING_SUCCESS';
export const DISCARD_LISTING_ERROR = 'app/ManageListingsPage/DISCARD_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  ownEntities: {},
  openingListing: null,
  openingListingError: null,
  closingListing: null,
  closingListingError: null,
  discardListingDraftInProgress: false,
  discardDraftId: null,
  discardListingDraftError: null,
};

const resultIds = data => data.data.map(l => l.id);

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponse),
  };
};

const updateListingAttributes = (state, listingEntity) => {
  const oldListing = state.ownEntities.ownListing[listingEntity.id.uuid];
  const updatedListing = { ...oldListing, attributes: listingEntity.attributes };
  const ownListingEntities = {
    ...state.ownEntities.ownListing,
    [listingEntity.id.uuid]: updatedListing,
  };
  return {
    ...state,
    ownEntities: { ...state.ownEntities, ownListing: ownListingEntities },
  };
};

const manageListingsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case OPEN_LISTING_REQUEST:
      return {
        ...state,
        openingListing: payload.listingId,
        openingListingError: null,
      };
    case OPEN_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        openingListing: null,
      };
    case OPEN_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        openingListing: null,
        openingListingError: {
          listingId: state.openingListing,
          error: payload,
        },
      };
    }

    case CLOSE_LISTING_REQUEST:
      return {
        ...state,
        closingListing: payload.listingId,
        closingListingError: null,
      };
    case CLOSE_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        closingListing: null,
      };
    case CLOSE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        closingListing: null,
        closingListingError: {
          listingId: state.closingListing,
          error: payload,
        },
      };
    }

    case ADD_OWN_ENTITIES:
      return merge(state, payload);
    case DISCARD_LISTING_REQUEST:
      return {
        ...state,
        discardListingDraftInProgress: true,
        discardListingDraftError: null,
        discardDraftId: payload,
      };
    case DISCARD_LISTING_SUCCESS:
      return {
        ...state,
        discardListingDraftInProgress: false,
        discardDraftId: null,
        // ...updateListingAttributes(state, payload.data),
      };
    case DISCARD_LISTING_ERROR:
      return {
        ...state,
        discardListingDraftInProgress: false,
        discardListingDraftError: payload,
        discardDraftId: null,
      };
    default:
      return state;
  }
};

export default manageListingsPageReducer;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getOwnListingsById = (state, listingIds) => {
  const { ownEntities } = state.ManageListingsPage;
  const resources = listingIds.map(id => ({
    id,
    type: 'ownListing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(ownEntities, resources, throwIfNotFound);
};

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)
export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

export const openListingRequest = listingId => ({
  type: OPEN_LISTING_REQUEST,
  payload: { listingId },
});

export const openListingSuccess = response => ({
  type: OPEN_LISTING_SUCCESS,
  payload: response.data,
});

export const openListingError = e => ({
  type: OPEN_LISTING_ERROR,
  error: true,
  payload: e,
});

export const closeListingRequest = listingId => ({
  type: CLOSE_LISTING_REQUEST,
  payload: { listingId },
});

export const closeListingSuccess = response => ({
  type: CLOSE_LISTING_SUCCESS,
  payload: response.data,
});

export const closeListingError = e => ({
  type: CLOSE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// Throwing error for new (loadData may need that info)
export const queryOwnListings = queryParams => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(queryParams));

  const { perPage, ...rest } = queryParams;
  const params = { ...rest, perPage };

  return sdk.ownListings
    .query(params)
    .then(response => {
      const { data } = response?.data;
// console.log("language---",languageType())
      if (languageType() === 'el') {
        data.map(item => {
          const { attributes } = item || {};
          const elTitle = attributes?.publicData?.el_title;
          attributes.title = elTitle ? elTitle : attributes?.title;
          attributes.publicData.department = attributes?.publicData?.el_departmentLabel
            ? attributes?.publicData?.el_departmentLabel
            : attributes?.publicData?.department;
          attributes.publicData.designer = attributes?.publicData?.el_designerLabel
            ? attributes?.publicData?.el_designerLabel
            : attributes?.publicData?.designer;
          attributes.publicData.category = attributes?.publicData?.el_categoryLabel
            ? attributes?.publicData?.el_categoryLabel
            : attributes.publicData?.category;
          attributes.publicData.subCategory = attributes?.publicData?.el_subCategoryLabel
            ? attributes?.publicData?.el_subCategoryLabel
            : attributes?.publicData?.subCategory;
          attributes.publicData.colour = attributes?.publicData?.el_colourLabel
            ? attributes?.publicData?.el_colourLabel
            : attributes?.publicData?.colour;
        });
      } else {
        data.map(item => {
          const { attributes } = item || {};
          const enTitle = attributes?.publicData?.en_title;
          attributes.title = enTitle ? enTitle : attributes?.title;
          attributes.publicData.department = attributes?.publicData?.en_departmentLabel
            ? attributes?.publicData?.en_departmentLabel
            : attributes?.publicData?.department;
          attributes.publicData.designer = attributes?.publicData?.en_designerLabel
            ? attributes?.publicData?.en_designerLabel
            : attributes?.publicData?.designer;
          attributes.publicData.category = attributes?.publicData?.en_categoryLabel
            ? attributes?.publicData?.en_categoryLabel
            : attributes?.publicData?.category;
          attributes.publicData.subCategory = attributes?.publicData?.en_subCategoryLabel
            ? attributes?.publicData?.en_subCategoryLabel
            : attributes?.publicData?.subCategory;
          attributes.colour = attributes?.publicData?.en_colourLabel
            ? attributes?.publicData?.en_colourLabel
            : attributes?.publicData?.colour;
        });
      }
      dispatch(addOwnEntities(response));
      dispatch(queryListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};

export const closeListing = listingId => (dispatch, getState, sdk) => {
  dispatch(closeListingRequest(listingId));

  return sdk.ownListings
    .close({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(closeListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(closeListingError(storableError(e)));
    });
};

export const openListing = listingId => (dispatch, getState, sdk) => {
  dispatch(openListingRequest(listingId));

  return sdk.ownListings
    .open({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(openListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(openListingError(storableError(e)));
    });
};
export const discardDraftListing = (listingId, search, config) => (dispatch, getState, sdk) => {
  dispatch({ type: DISCARD_LISTING_REQUEST, payload: listingId });
  const queryParams = search;
  const page = queryParams.page || 1;

  const aspectWidth = 1;
  const aspectHeight = 1;
  const variantPrefix = 'default';
  const aspectRatio = 1.33;
  // const aspectRatio = aspectHeight / aspectWidth;
  return sdk.ownListings
    .discardDraft({ id: listingId })
    .then(response => {
      dispatch({ type: DISCARD_LISTING_SUCCESS, payload: response });
      dispatch(
        queryOwnListings({
          ...queryParams,
          page,
          perPage: RESULT_PAGE_SIZE,
          include: ['images', 'currentStock'],
          'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
          // ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
          // ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
          'limit.images': 1,
        })
      );
      return response;
    })
    .catch(e => {
      dispatch({ type: DISCARD_LISTING_ERROR, error: true, payload: storableError(e) });
    });
};
export const loadData = (params, search, config) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return queryOwnListings({
    ...queryParams,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['images', 'currentStock'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    // 'fields.listing': [
    //     'title',
    //     'geolocation',
    //     'price',
    //     'publicData.listingType',
    //     'publicData.unitType',
    //     'publicData.designer',
    //     'publicData.department',
    //     'publicData.category',
    //     'publicData.subCategory',
    //     'publicData.size',
    //     'publicData.otherBrand',
    //     'publicData.en_title',
    //     'publicData.el_title',
    //     'publicData.refrenceNumber',

    //     // These help rendering of 'purchase' listings,
    //     // when transitioning from search page to listing page
    //     'publicData.pickupEnabled',
    //     'publicData.shippingEnabled',
    //   ],
    // ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    // ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  });
};
