import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { languageType } from '../../util/languageType';
export const loadData = (params, search) => async dispatch => {
  let pageId = params.pageId;
  let newp = pageId;
  try {
    pageId = languageType() === 'en-US' ? pageId : `${pageId}-greek`;
    console.log('pageId', pageId);

    const pageAsset = { [pageId]: `content/pages/${pageId}.json` };
    const hasFallbackContent = false;
    const res = await dispatch(fetchPageAssets(pageAsset, hasFallbackContent, pageId));
    if (!res) {
      const pageAsset = { [newp]: `content/pages/${newp}.json` };

      dispatch(fetchPageAssets(pageAsset, hasFallbackContent, pageId));
    }
    // else{

    // }
  } catch (e) {
    console.log('eeee', e);
  }
};
